export enum EntitiesEnum {
  Profile = "PROFILE",
  ProfileEdit = "PROFILE_EDIT",
  ProfileEditName = "PROFILE_EDIT_NAME",
  ProfileGettingPaid = "PROFILE_GETTING_PAID",
  ProfileEmail = "PROFILE_EMAIL",
  ProfilePassword = "PROFILE_PASSWORD",
  ProfileLanguages = "PROFILE_LANGUAGEs",
  ProfileAppMode = "PROFILE_APP_MODE",
  ProfilePreferences = "PROFILE_PREFERENCES",
  ProfileNotifications = "PROFILE_NOTIFICATIONS",
  ProfileLocation = "PROFILE_LOCATION",
  ProfileAddSocialLink = "PROFILE_ADD_SOCIAL_LINK",
  ProfileIdentityVerification = "PROFILE_IDENTITY_VERIFICATION",
  VerifyPersonalInfo = "PROFILE_VERIFY_PERSONAL_INFO",
  ProfilePaymentMethods = "PROFILE_PAYMENT_METHODS",
  ProfileAddCard = "PROFILE_ADD_CARD",
  ProfileBookingHistory = "PROFILE_BOOKING_HISTORY",
  ProfileWorkingSchedule = "PROFILE_WORKING_SCHEDULE",
  ProfilePronounce = "PROFILE_PRONOUNCE",
  ProfileEditTrainer = "PROFILE_EDIT_TRAINER",
  ProfileGymLocation = "PROFILE_GYM_LOCATION",
  ProfileChoosePlace = "PROFILE_CHOOSE_PLACE",
  ProfileOrderConfirmation = "PROFILE_ORDER_CONFIRMATION",
  ProfileMembership = "PROFILE_MEMBERSHIP",
  ProfileCalendar = "PROFILE_CALENDAR",
  SubscriptionPaymentMethod = "SUBSCRIPTION_PAYMENT_METHOD",
  ProfileScan = "PROFILE_SCAN",
  ProfileEditGym = "PROFILE_EDIT_GYM",
  ChooseLocation = "CHOOSE_LOCATION",
  AddNewGym = "ADD_NEW_GYM",
  Login = "LOGIN",
  Register = "REGISTER",
  VerifyEmail = "VERIFY_EMAIL",
  RegistrationDone = "REGISTRATION_DONE",
  MobileOrderCalendar = "MOBILE_ORDER_CALENDAR",
  CheckEmail = "CHECK_EMAIL",
  RequestDelete = "REQUEST_DELETE",
  RequestDeleteConfirm = "REQUEST_DELETE_CONFIRM",
  ForgotPassword = "FORGOT_PASSWORD",
  ForgotPasswordCheckEmail = "FORGOT_PASSWORD_CHECK_EMAIL",
  UpdateEmail = "UPDATE_EMAIL",
  PasswordReset = "PASSWORD_RESET",
  ManagerPasswordSet = "MANAGER_PASSWORD_SET",
  Quizz = "QUIZZ",
  Facilities = "FACILITIES",
  FacilitiesWithType = "FACILITIES_WITH_TYPE",
  Membership="MEMBERSHIP",
  ChangeMembership = "CHANGE_MEMBERSHIP",
  Facility = "FACILITY",
  FacilityItem = "FACILITYITEM",
  Trainers = "TRAINERS",
  TrainerPreview = "TRAINER_PREVIEW",
  TrainerBooking = "TRAINER_BOOKING",
  Trainer = "TRAINER",
  ChatList = "CHAT",
  ChatPersonal = "CHAT_PERSONAL",
  ChoosePlace = "CHOOSE_PLACE",
  ChooseGym = "CHOOSE_GYM",
  FacilityReviews = "FASILITY_REVIEWS",
  TrainerReviews = "TRAINER_REVIEWS",
  BookTrainer = "BOOK_TRAINER",
  ConfirmOrder = "CONFIRM_ORDER",
  PaymentsMethods = "PAYMENTS_METHODS",
  PaymentMethod = "PAYMENT_METHOD",
  PaymentSuccess = "PAYMENT_SUCCESS",
  SuccessMembership = "SUCCESS_MEMBERSHIP",
  SuccessStripeConnect = "SUCCESS_STRIPE_CONNECT",
  TrainerRateSession = "TRAINER_RATE_SESSION",
  TrainerReviewLike = "TRAINER_REVIEW_LIKE",
  TrainerReviewDislike = "TRAINER_REVIEW_DISLIKE",
  TrainerDemo = "TRAINER_DEMO",
  DiscoverTrainers = "DISCOVER_TRAINERS",
  Discover = "DISCOVER",
  FacilityItemPreview = "FACILITY_ITEM_PREVIEW",
  FacilityRateSession = "FACILITY_RATE_SESSION",
  FacilityReviewLike = "FACILITY_REVIEW_LIKE",
  FacilityReviewDislike = "FACILITY_REVIEW_DISLIKE",
  TrainersSchedule = "TRAINERS_SCHEDULE",
  Dashboard = "DASHBOARD",
  DashboardEvents = "DASHBOARD_EVENTS",
  DashboardCalendar = "DASHBOARD_CALENDAR",
  DashboardUserCalendar = "DASHBOARD_USER_CALENDAR",
  DashboardTrainerReviews = "DASHBOARD_TRAINER_REVIEWS",
  DashboardDiscover = "DASHBOARD_Discover",
  ActivityOnDate = "ACTIVITY_ON_DATE",
  ActivityDetail = "ACTIVITY_DETAIL",
  UserDashboardCalendar = "USER_DASHBOARD_CALENDAR",
  FacilitiesEvents = "FACILITIES_EVENTS",
  TrainersExtraPersonal = "TRAINERS_EXTRA_PERSONAL",
  UploadingPhoto = "UPLOADING_PHOTO",
  TrainersExtraFacilities = "TRAINERS_EXTRA_FACILITIES",
  ProfilePreview = "PROFILE_PREVIEW",
  TrainerSchedule = "TRAINER_SCHEDULE",
  Recent = "RECENT",
  Positive = "POSITIVE",
  Negative = "NEGATIVE",
  FacilityDates = "FACILITY_DATES",
  FacilitySubscription = "FACILITY_SUBSCRIPTION",
  FacilityOrder = "FACILITY_ORDER",
  Default = "DEFAULT",
  WriteReview = "WRITE_REVIEW",
  TrainingReview = "TRAINING_REVIEW",
  WriteTrainingReview = "WRITE_TRAINING_REVIEW",
  TrainingReviewSuccessful = "WRITE_TRAINING_SUCCESSFUL",
  CreateFacility = "CREATE_FACILITY",
  RegisterCreateEvent = "REGISTER_CREATE_EVENT",
  RegisterExerciseList = "REGISTER_EXERCISE_LIST",
  TrainerWorkouts = "TRAINER_WORKOUTS",
  TrainerWorkoutsUploading = "TRAINER_WORKOUTS_UPLOADING",
  TrainerWorkoutTypes = "TRAINER_WORKOUT_TYPES",
  TrainerMuscleTypes = "TRAINER_MUSCLE_TYPES",
  TrainerCreateWorkout = "TRAINER_CREATE_WORKOUT",
  TrainerPreviewExercise = "TRAINER_PREVIEW_EXERCISE",
  TrainerEditExercise = "TRAINER_EDIT_EXERCISE",
  TrainerCreateExercise = "TRAINER_CREATE_EXERCISE",
  TrainerExerciseList = "TRAINER_EXERCISE_LIST",
  CreateWorkout = "CREATE_WORKOUT",
  WorkoutTypes = "WORKOUT_TYPES",
  MuscleTypes = "MUSCLE_TYPES",
  CreateExercise = "CREATE_EXERCISE",
  ActivitiesNearby = "ACTIVITIESNEARBY",
  MyTraining = "MY_TRAINING",
  Nearby = "NEARBY",
  Events = "EVENTS",
  EventsDetailed = "EVENTS_DETAILED",
  EventsNewDetailed = "EVENTS_DETAILED",
  EventOrder = "EVENT_ORDER",
  Favourites = "FAVOURITES",
  EmptyState = "EMPTY_STATE",
  TrainerEvents = "TRAINER_EVENTS",
  Trainings = "TRAININGS",
  Amenities = "AMENITIES",
  User = "USER",
  CreateEvent = "CREATE_EVENT",
  EditEvent = "EDIT_EVENT",
  NearbyGym = "NEARBY_GYM",
  Workouts = "WORKOUTS",
  WorkoutOrder = "WORKOUT_ORDER",
  Gyms = "GYMS",
  GymDetails = "GYMS_DETAILS",
  GymReviews = "GYMS_REVIEWS",
  GymOrderCalendar = "GYMS_ORDER_CALENDAR",
  GymPaymentMethod = "GYMS_PAYMENT_METHOD",
  GymsNearby = "GYMS_NEARBY",
  TrainersNearby = "TRAINERS_NEARBY",
  UserWorkouts = "USER_WORKOUTS",
  UserWorkout = "USER_WORKOUT",
  UserPreviewDaily = "USER_PREVIEW_DAILY",
  UserAddReview = "USER_ADD_REVIEW",
  UserWriteReview = "USER_WRITE_REVIEW",
  UserPurchasedWorkout = "USER_PURCHASED_WORKOUT",
  UserWorkoutPayment = "USER_WORKOUT_PAYMENT",
  UserPurchasedWorkouts = "USER_PURCHASED_WORKOUTS",
  UserEventDetail = "USER_EVENT_DETAILS",
  FacilitiesOld = "FACILITIES_OLD",
  Training = "TRAINING",
  TrainingList = "TRAINING_LIST",
  TrainerScheduleCalendar = "TRAINER_SCHEDULE_CALENDAR",
  TrainerWorkoutList = "TRAINER_WORKOUT_LIST",
  UserWorkoutList = "USER_WORKOUT_LIST",
  CreateProfileAccount = "CREATE_PROFILE_ACCOUNT",
  ChooseGymAccount = "CHOOSE_GYM_ACCOUNT",
  FreelancerTrainer = "FREELANCER_TRAINER",
  LocationRate = "LOCATION_RATE",
  CreateBothProfileAccount = "CREATE_BOTH_PROFILE_ACCOUNT",
  State = "STATE",
  City = "CITY",
  Address = "ADDRESS",
  UserExercisesList = "USER_EXERCISES_LIST",
  SelectRole = "SELECT_ROLE",
  SelectName = "SELECT_NAME",
  ReferralCode = "REFERER_code",
  SelectMembership = "SELECT_Membership",
  StartMembership = "START_Membership",
  TrainersUpcomingTrainings = "TRAINERS_UPCOMING_TRAININGS",
  TrainersEventTraining = "TRAINERS_EVENT_TRAINING",
  TrainerUserProfile = "TRAINER_USER_PROFILE",
  Event = "EVENT",
  Workout = "WORKOUT",
  Language = "LANGUAGE",
  AppMode = "APP_MODE",
  UserWorkoutPaymentSuccess = "USER_WORKOUT_PAYMENT_SUCCESS",
  AddReview = "ADD_REVIEW",
  MaxVideoDuration = "MAX_VIDEO_DURATION",
  MinVideoDuration = "MIN_VIDEO_DURATION",
  MaxVideoSize = "MAX_VIDEO_SIZE",
  WorkoutPreview = "WORKOUT_PREVIEW",
  UserPreviewExercise = "USER_PREVIEW_EXERCISE",
  BookedTraining = "BOOKED_TRAINING",
  ChooseFacilityType = "CHOOSE_FACILITY_TYPE",
  CreateFacilityType = "CREATE_FACILITY_TYPE",
  EnterCode = "ENTER_CODE",
  SelectExistingFacility = "SELECT_EXISTING_FACILITY",
  FacilityPreview = "FACILITY_PREVIEW",
  TrainingDetails = "TRAINING_DETAILS",
  Bundle = "BUNDLE",
  Session = "SESSION",
  SendReviewForm = "SEND_REVIEW_FORM",
  BookMore = "BOOK_MORE",
  DashboardOverview = "DASHBOARD_OVERVIEW",
  DashboardUserOverview = "DASHBOARD_USER_OVERVIEW",
  DashboardBookings = "DASHBOARD_BOOKINGS",
  DashboardBookingsHistory = "DASHBOARD_BOOKINGS_HISTORY",
  DashboardTrainings = "DASHBOARD_TRAININGS",
  DashboardTrainingsCalendar = "DASHBOARD_TRAININGS_CALENDAR",
  DashboardUserProfile = "DASHBOARD_USER_PROFILE",
  DashboardUserProfilePreview = "DASHBOARD_USER_PROFILE_PREVIEW",
  DashboardEvent = "DASHBOARD_EVENT",
  DashboardEventDetail = "DASHBOARD_EVENT_DETAIL",
  DashboardCreateEvent = "DASHBOARD_CREATE_EVENT",
  DashboardEditEvent = "DASHBOARD_EDIT_EVENT",
  DashboardMembership = "DASHBOARD_MEMBERSHIP",
  DashboardStartMembership = "DASHBOARD_START_MEMBERSHIP",
  DashboardSelectMembership = "DASHBOARD_SELECT_MEMBERSHIP",
  DashboardWorkout = "DASHBOARD_WORKOUT",
  DashboardCreateWorkout = "DASHBOARD_CREATE_WORKOUT",
  DashboardEditWorkout = "DASHBOARD_EDIT_WORKOUT",
  DashboardWorkoutTypes = "DASHBOARD_WORKOUT_TYPES",
  DashboardMuscleTypes = "DASHBOARD_MUSCLE_TYPES",
  DashboardCreateExercise = "DASHBOARD_CREATE_EXERCISE",
  DashboardExerciseList = "DASHBOARD_EXERCISE_LIST",
  DashboardGettingPaid = "DASHBOARD_GETTING_PAID",
  DashboardSettings = "DASHBOARD_SETTINGS",
  DashboardPassList = "DASHBOARD_PASS_LIST",
  DashboardPassViewList = "DASHBOARD_PASS_VIEW_LIST",
  DashboardDropinsPassDetail = "DASHBOARD_DROPINS_PASS_DETAIL",
  DashboardPassCreate = "DASHBOARD_PASS_CREATE",
  DashboardPassProfile = "DASHBOARD_PASS_PROFILE",
  DashboardDropinList = "DASHBOARD_DROPIN_LIST",
  DashboardDropinViewList = "DASHBOARD_DROPIN_VIEW_LIST",
  DashboardDropinCreate = "DASHBOARD_DROPIN_CREATE",
  DashboardDropinProfile = "DASHBOARD_DROPIN_PROFILE",
  DashboardGymManager = "DASHBOARD_GYM_MANAGER",
  DashboardGymManagerProfile = "DASHBOARD_GYM_MANAGER_PROFILE",
  DashboardManageGyms = "DASHBOARD_MANAGE_GYMS",
  DashboardListGyms = "DASHBOARD_LIST_GYMS",
  DashboardGymCreate = "DASHBOARD_GYM_CREATE",
  DashboardMessage = "DASHBOARD_MESSAGE",
  DashboardClientDailys = "DASHBOARD_CLIENT_DAILYS",
  DashboardClientPurchasedDailys = "DASHBOARD_CLIENT_PURCHASED_DAILYS",
  Overview = "Overview",
  FacilityPass = "FACILITY_PASSES",
  FacilityPassList = "FACILITY_PASS_LIST",
  FacilityDropins = "FACILITY_DROPINS",
  FacilityDropinsList = "FACILITY_DROPINS_LIST",
  FacilityWorkout = "FACILITY_WORKOUT",
  FacilityCreateWorkout = "FACILITY_CREATE_WORKOUT",
  FacilityCreateEvent = "FACILITY_CREATE_EVENT",
  ViewPassAndDropins = "VIEW_PASS_AND_DROPINS",
  ManagersOverview = "MANAGERS_OVERVIEW",
  AddManager = "ADD_MANAGER",
  EditManager = "EDIT_MANAGER",
  ManagerProfile = "MANAGER_PROFILE",
  CreateItem = "CREATE_ITEM",
  EditItem = "EDIT_ITEM",
  CreateFacilitySuccess = "CREATE_FACILITY_SUCCESS",
  CreateDailys = "CREATE_DAILYS",
  WorkoutList = "WORKOUT_LIST",
  WorkoutView = "WORKOUT_VIEW",
  WorkoutReviews = "WORKOUT_REVIEWS",
  EditWorkout = "EDIT_WORKOUT",
  Upcoming = "UPCOMING",
  Dailys = "DAILYS",
  Trending = "TRENDING",
  EmploymentType = "EMPLOYMENT_TYPE",
  DashboardDropinsPassesDetail = "DASHBOARD_DROPINS_PASSES_DETAIL",
  DropinsPassesDetail = "DROPINS_PASSES_DETAIL",
}
